export enum AuthQueryList {
  logout = '/v1/auth/logout',
  authorize = '/v1/auth/authorize',
  user = '/v1/auth/user',
  getAssociates = '/v1/associates',
}

export const SettingsQueries = {
  getPermissions: () => `/v1/permission`,
  createPermission: () => `/v1/permission`,
  updatePermission: (permissionId: string) => `/v1/permission/${permissionId}`,
  deletePermission: (permissionId: string) => `/v1/permission/${permissionId}`,
  getRoles: () => `/v1/role`,
  createRole: () => `/v1/role`,
  updateRole: (roleId: string) => `/v1/role/${roleId}`,
  deleteRole: (roleId: string) => `/v1/role/${roleId}`,
  assignPermissions: (roleId: string) => `/v1/role/${roleId}/assignPermissions`,
  getDepartments: () => `/v1/department`,
  createDepartment: () => `/v1/department`,
  updateDepartment: (departmentId: string) => `/v1/department/${departmentId}`,
  deleteDepartment: (departmentId: string) => `/v1/department/${departmentId}`,
  getUsers: () => `/v1/user`,
  inviteUser: () => `/v1/user`,
  deleteUser: (userId: string) => `/v1/user/${userId}`,
  updateUser: (userId: string) => `/v1/user/${userId}`,
};

export const UsersQueries = {
  getUsers: () => `/v1/user`,
  getUser: (userId: string) => `/v1/user/${userId}`,
  updateUser: (userId: string) => `/v1/user/${userId}`,
  deleteUser: (userId: string) => `/v1/user/${userId}`,
  inviteUser: () => `/v1/user`,
};

export const CodesQueryList = {
  getListCodeGroups: (pagination: boolean, page?: number, limit?: number, brand?: string) =>
    pagination
      ? `/v1/codeGroup?pagination=${pagination}&page=${page}&limit=${limit}&sortBy=id&sortOrder=DESC${
          brand && `&brand=${brand}`
        }`
      : `/v1/codeGroup?sortBy=id&sortOrder=DESC`,
  createCodeGroup: () => `/v1/codeGroup`,
  getCodeGroup: (groupId: string) => `/v1/codeGroup/${groupId}`,
  updateCodeGroup: (groupId: string) => `/v1/codeGroup/${groupId}`,
  deleteCodeGroup: (groupId: string) => `/v1/codeGroup/${groupId}`,
  shareCodeGroup: (groupId: string) => `/v1/codeGroup/${groupId}/share`,
  getListPromoCodes: (groupId: string) => `/v1/codeGroup/${groupId}/promoCode`,
  addPromoCode: (groupId: string) => `/v1/codeGroup/${groupId}/promoCode`,
  getPromoCode: (groupId: string, codeId: string) => `/v1/codeGroup/${groupId}/promoCode/${codeId}`,
  updatePromoCode: (groupId: string, codeId: string) => `/v1/codeGroup/${groupId}/promoCode/${codeId}`,
  deletePromoCode: (groupId: string, codeId: string) => `/v1/codeGroup/${groupId}/promoCode/${codeId}`,
  sendPromoCode: (groupId: string, codeId: string) => `/v1/codeGroup/${groupId}/promoCode/${codeId}/send`,
  metrics: () => `/v1/metrics/promoCode`,
  uploadGuestEmails: (groupId: string) => `/v1/codeGroup/${groupId}/uploadGuestEmails`,
  sendNotSentGuestEmails: (groupId: string) => `/v1/codeGroup/${groupId}/sendNotSentGuestEmails`,
  shareCode: (groupId: string, codeId: string, unshare: boolean) =>
    `/v1/codeGroup/${groupId}/promoCode/${codeId}/externalShare?unShare=${unshare}`,
  resetGroup: (groupId: string) => `/v1/codeGroup/${groupId}/guest`,
  //guests
  getCodeGroupGuests: (groupId: string) => `/v1/codeGroup/${groupId}/guest`,
  addCodeGroupGuest: (groupId: string) => `/v1/codeGroup/${groupId}/guest`,
  updateCodeGroupGuest: (groupId: string, guestId: string) => `/v1/codeGroup/${groupId}/guest/${guestId}`,
  deleteGuest: (groupId: string, guestId: string) => `/v1/codeGroup/${groupId}/guest/${guestId}`,
  sendMultipleGuestEmails: (groupId: string) => `/v1/codeGroup/${groupId}/sendMultipleGuestEmails`,
  //sellers
  getSellers: () => `/v1/sellers`,

  getBrands: () => `/v1/brands`,
};
